.open_hours {
    height: auto;
    /* background-color: white; */
}

.open_hours-item {
    display: grid;
    grid-template-columns: 7rem 7rem 13rem 1rem 13rem;
    align-items: center;
    justify-items: center;
}

.open-time {
    display: flex;
    background-color: var(--first-color-lighter);
    padding: .3rem 1rem;
    border-radius: 5px;
    color: var(--body-color);
    align-items: center;
}