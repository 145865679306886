.time_picker{
    position: relative;
}

.time_picker-droper{
    display: grid;
    grid-template-columns: 3rem 3rem 3rem;
    justify-items: center;
    height: auto;
    position: absolute;
    z-index: 99;
    background-color: var(--first-color-lighter);
    border-radius: .3rem;
    box-shadow: var(--card-shadow);
}

.time_picker-col {
    position: relative;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.time_picker-col div{
    width: 100%;
    padding:5px
}

.time_picker-col div:hover{
    /* width: 100%; */
    background-color: var(--first-color);
    color: #fff;
}

.show-time{
    display: flex;
    align-items: center;
}