@import-normalize;


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins',
    sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

li {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #8D29FF;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@layer components {
  .guide-icon {
    @apply flex-shrink-0 w-8 h-8;
  }
  .sidebar_icon_active {
    @apply relative flex w-12 h-12 rounded-xl text-xl flex-row items-center justify-center p-2 aspect-square space-x-4 my-3 bg-primary-500 text-white;
  }
  .sidebar_icon_inactive {
    @apply relative flex w-12 h-12 rounded-xl text-xl flex-row items-center justify-center p-2 aspect-square space-x-4 my-3;
  }
  .dashboard {
    @apply flex-1 relative;
  }

  .sub-title {
    @apply font-medium text-gray-400 text-lg flex items-start flex-1 mx-2
  }
  .ease_transition {
    @apply transform transition-all duration-500 ease-in-out;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #dbc1fa;
    border-radius: 100vh;
    /* border: 3px solid #f6f7ed; */
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

  .scrollbar-sm::-webkit-scrollbar {
    width: 5px;
    height: 20px;
  }

  .scrollbar-sm::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar-sm::-webkit-scrollbar-thumb {
    background: #dbc1fa;
    border-radius: 100vh;
    /* border: 3px solid #f6f7ed; */
  }

  .scrollbar-h-sm::-webkit-scrollbar {
    width: 20px;
    height: 5px;
  }

  .scrollbar-h-sm::-webkit-scrollbar-thumb {
    background: #dbc1fa;
    border-radius: 100vh;
    /* border: 3px solid #f6f7ed; */
  }

  .scrollbar-sm::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}
